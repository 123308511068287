import React, {Component} from 'react';
import {graphql} from 'gatsby';


import registerIcons from '../styles/registerIcons';
import SEO from '../components/shared/SEO';
import Navbar from '../components/shared/Navbar';
import ContentMdPageTemplate from '../components/templatecomponents/ContentMdPageTemplate';
import Footer from '../components/shared/Footer';

// font awesome library needs to be created via entry point of app
// so needed here in case of refresh on this screen
registerIcons();

class MdPageTemplate extends Component {

  render() {
    const {article} = this.props.data;
    return (
      <div>
        <header>
          <SEO title={article.frontmatter.page_title} description={article.frontmatter.page_description} />
          <Navbar />
        </header>
        <main>
          <ContentMdPageTemplate data={this.props.data} />
        </main>
        <Footer />
      </div>
    );
  }
}
export const query = graphql`
  query($slug: String!) {
    article: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        expired
        small_header
        article_title
        page_title
        page_description
        link_url
        apply_url
      }
    }
    template: markdownRemark(frontmatter: { path: { eq: "/md-page-template/" } }) {
      frontmatter {
        button_text_case_study
        button_text_job_posting
        share_icon
        contact_icon
      }
    }
    expired_job_message: markdownRemark(frontmatter: { path: { eq: "/expired-job-message/" } }) {
      html
    }
    contact: markdownRemark(frontmatter: { path: { eq: "/contact-info/" } }) {
      frontmatter {
        info_href
        hr_href
      }
    }
  }
`;

export default MdPageTemplate;