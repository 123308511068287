import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import registerIcons from '../../styles/registerIcons';
import styles from './contentMdPageTemplate.module.scss';

// font awesome library needs to be created via entry point of app
// so needed here in case of refresh on this screen
registerIcons();

class ContentMdPageTemplate extends Component {


  render() {
    const {article, contact, expired_job_message, template} = this.props.data;
    const {html} = article;

    const getExpiredHeader = () => {
      if (article.frontmatter.expired == 'true')
        return <div className={styles.expired} dangerouslySetInnerHTML={{__html: expired_job_message.html}} />;
    };

    const getInTouchButton = () => {
      const address = contact.frontmatter.info_href;
      const href = address + '?subject=RE: ' + article.frontmatter.article_title;

      return (
        <a className={styles.button} target="_blank" rel="noopener noreferrer" href={href}>
          {template.frontmatter.button_text_case_study}
        </a>
      );
    };

    const getApplyButton = () => {
      if (article.frontmatter.expired != 'true' && article.frontmatter.apply_url) {
        return (
          <a className={styles.button} target="_blank" rel="noopener noreferrer" href={article.frontmatter.apply_url}>
            {template.frontmatter.button_text_job_posting}
          </a>
        );
      }
    };


    const getMailToSubject = () => {
      const address = contact.frontmatter.info_href;
      const href = address + '?subject=RE: ' + article.frontmatter.article_title;
      return href;
    };

    const getAskUsButton = () => {
      if (article.frontmatter.small_header.toLowerCase() == 'case study') {
        return (
          <a target="_blank" data-testid="ask-us" id="ask-us" rel="noopener noreferrer" href={getMailToSubject()} className={styles.iconLink}>
            <FontAwesomeIcon icon={['fal', 'comment-alt']} className={styles.iconStyle} />
            <p className={styles.iconText}>{template.frontmatter.contact_icon}</p>
          </a>
        );
      }
      return null;
    };

    const copyToClipboard = () => {
      var toBeCopied = document.createElement('input');
      document.body.appendChild(toBeCopied);
      toBeCopied.setAttribute('value', `https://www.cornerstonenw.com/${article.frontmatter.link_url}`);
      toBeCopied.select();
      document.execCommand('copy');
      document.body.removeChild(toBeCopied);
      alert('Copied URL to clipboard!');
    };

    const getShareButton = () => {
      if (article.frontmatter.expired != 'true') {
        return (
          <button
            type="button"
            onClick={() => copyToClipboard()}
            aria-label={`copy-https://www.cornerstonenw.com/${article.frontmatter.link_url}`}
            data-testid="share"
            className={styles.iconButton}
            id="share"
          >
            <FontAwesomeIcon icon={['fal', 'share-alt']}  className={styles.iconStyle} />
            <p className={styles.iconText}>{template.frontmatter.share_icon}</p>
          </button>
        );
      }
    };

    return (
      <section aria-label='main-content' className={'offset-md-2 col-md-8' + ' ' + styles.content}>
        <div className={styles.navBackground}></div>
        {getExpiredHeader()}
        <p className={styles.intro} aria-label="type-of-content">{article.frontmatter.small_header}</p>
        <h1 className={styles.title} id="case-study-title">{article.frontmatter.article_title}</h1>
        <div className={'row' + ' ' + styles.iconRow}>
          {getShareButton()}
          {getAskUsButton()}
        </div>
        <div className={styles.mdHTML} dangerouslySetInnerHTML={{__html: html}} />
        {article.frontmatter.small_header.toLowerCase() == 'job opening' ? getApplyButton() : getInTouchButton()}
      </section>
    );
  }
}

export default ContentMdPageTemplate;